/* eslint-disable no-restricted-syntax */
import { combinePredicate } from '@inwink/utils/methods/combinepredicate';
import { removeDiacritics } from '@inwink/utils/methods/removediacritics';
import { getPropertyValue } from '@inwink/utils/methods/getpropertyvalue';
import { parse } from '@inwink/utils/querystring';
import * as moment from 'moment';
import { Entities } from '@inwink/entities/entities';

export function parseQuery(search: string) {
    return parse(search, null);
}
export function getEntityPredicate<T>(filter: any, fieldtemplate: Entities.IFieldTemplate,
    searchable: Entities.IFilterTemplate, noValNoShow?: boolean): (T) => boolean {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let predicate = (entity: T) => true;

    if (filter) {
        if (filter.searchtext && filter.searchtext.length > 2 && searchable && searchable.textsearch) {
            const searchtext = removeDiacritics(filter.searchtext).toLowerCase();
            predicate = combinePredicate(predicate, (entity: T) => {
                let res = false;
                searchable.textsearch.forEach((f) => {
                    const val = getPropertyValue(entity, f);
                    if (val) {
                        if (typeof val === "string") {
                            res = res || removeDiacritics(val.toLowerCase()).indexOf(searchtext) >= 0;
                        } else {
                            for (const k in val) {
                                if (Object.prototype.hasOwnProperty.call(val, k) && val[k]) {
                                    res = res || removeDiacritics(val[k].toLowerCase()).indexOf(searchtext) >= 0;   
                                }
                            }
                        }
                    }
                });
                return res;
            });
        }

        const filterNames = Object.keys(filter);
        filterNames.forEach((filtername) => {
            const cfilter = filtername.toLowerCase();
            const searchablefield = searchable && searchable.searchablefields
                ? searchable && searchable.searchablefields.filter((f) => f.name.toLowerCase() === cfilter)[0] : null;
            let entityField = fieldtemplate && fieldtemplate.template
                && fieldtemplate.template.fields.filter((f) => f.key.toLowerCase() === cfilter)[0];
            const fieldType = entityField && entityField.type.toLowerCase();

            if (searchablefield && searchablefield.fromEntities) {
                if (!entityField) {
                    entityField = fieldtemplate && fieldtemplate.template && fieldtemplate.template.fields
                        .filter((f) => f.key.toLowerCase() === searchablefield.fromEntities)[0];
                }

                predicate = combinePredicate(predicate, (entity: T) => {
                    let entityVal = entity[searchablefield.name];
                    if (!entityVal && fieldType === "entity") {
                        const foreignKeyName = (searchablefield).foreignKeyName ?? searchablefield.name + "Id";
                        entityVal = entity[foreignKeyName];
                        if (entityVal && typeof entityVal === "string") {
                            entityVal = [{ [searchablefield.mapping.id]: entityVal }];
                        }
                    }
                    if (!entityVal && noValNoShow) {
                        return false;
                    }
                    if (entityVal && searchablefield.fromEntityName) {
                        const entityFromVal = entityVal?.length && entityVal[0][searchablefield?.fromEntityName + "Id"];
                        if (entityFromVal) {
                            // entityVal = entityFromVal[searchablefield.mapping && searchablefield.mapping.id];
                            entityVal = [{ [searchablefield.mapping.id]: entityFromVal }];
                        }
                    }
                    let filterVal = filter[searchablefield.name];
                    if (!filterVal) {
                        return true;
                    }

                    if ((typeof filterVal === "string" || typeof filterVal === "number")
                        && (typeof entityVal === "string" || typeof entityVal === "number")) {
                        return entityVal === filterVal;
                    } if ((filterVal && filterVal.forEach && entityVal && entityVal.forEach)
                        || (typeof filterVal === "string" && entityVal && entityVal.forEach)) {
                        if (typeof filterVal === "string" && filterVal.indexOf("+") >= 0) {
                            filterVal = filterVal.split("+");
                        }

                        if (!filterVal.forEach) {
                            filterVal = [filterVal];
                        }

                        let loop = false;
                        for (let i = 0; i < entityVal.length; i++) {
                            loop = (filterVal.indexOf(entityVal[i][searchablefield.mapping.id]) >= 0);
                            if (loop) {
                                return true;
                            }
                        }

                        return false;
                    }
                    return true;
                });
            } else if (filtername === "timeslotday" && filter.timeslotday !== 'all') {
                predicate = combinePredicate(predicate, (entity: T) => {
                    const days = filter.timeslotday;
                    if (days) {
                        return days.some((d) => {
                            const day = moment(d);
                            const timeslots = (entity as any).timeslots;
                            if (timeslots && Array.isArray(timeslots) && timeslots.length > 0) {
                                const start = moment(timeslots[0].startDate).startOf('day');
                                const end = moment(timeslots[0].endDate).startOf('day');
                                return start.isSameOrBefore(day) && day.isSameOrBefore(end);
                            }
                            return false;
                        });
                    }
                });
            } else if (filtername === "timeslottime") {
                predicate = combinePredicate(predicate, (entity: T) => {
                    const time = filter.timeslottime;
                    const hours = time.hours;
                    const minutes = time.minutes;
                    const timeslots = (entity as any).timeslots;
                    if (timeslots && Array.isArray(timeslots) && timeslots.length > 0) {
                        const start = moment(timeslots[0].startDate);
                        const end = moment(timeslots[0].endDate);
                        const expected = moment(start).add(hours, 'hours').add(minutes, 'minutes');
                        if (!expected.isValid || !start.isValid || !end.isValid) {
                            return false;
                        }
                        return start.isSameOrBefore(expected) && expected.isSameOrBefore(end);
                    }
                    return false;
                });
            } else if (filtername === "timeslottimefrom") {
                predicate = combinePredicate(predicate, (entity: T) => {
                    const time = filter.timeslottimefrom;
                    if (time) {
                        const hours = time.hours;
                        const minutes = time.minutes;
                        const timeslots = (entity as any).timeslots;
                        if (timeslots && Array.isArray(timeslots) && timeslots.length > 0) {
                            const dateTime = moment(timeslots[0].startDate);
                            const minDateTime = dateTime.clone().hours(hours).minutes(minutes);
                            if (!minDateTime.isValid || !dateTime.isValid) {
                                return false;
                            }
                            return minDateTime.isSameOrBefore(dateTime);
                        }
                        return false;
                    }

                    return true;
                });
            } else if (filtername === "timeslottimeto") {
                predicate = combinePredicate(predicate, (entity: T) => {
                    const time = filter.timeslottimeto;
                    if (time) {
                        const hours = time.hours;
                        const minutes = time.minutes;
                        const timeslots = (entity as any).timeslots;
                        if (timeslots && Array.isArray(timeslots) && timeslots.length > 0) {
                            const dateTime = moment(timeslots[0].endDate);
                            const maxDateTime = dateTime.clone().hours(hours).minutes(minutes);
                            if (!maxDateTime.isValid || !dateTime.isValid) {
                                return false;
                            }
                            return dateTime.isSameOrBefore(maxDateTime);
                        }
                        return false;
                    }

                    return true;
                });
            } else if (entityField) {
                if (fieldType === "selectlist") {
                    predicate = combinePredicate(predicate, (entity: T) => {
                        let filterVal = filter[filtername];
                        if (!filterVal || filterVal === "empty") {
                            filterVal = [];
                        }

                        if (!entity[entityField.key]) return false;
                        if (typeof filterVal === "string") {
                            if (filterVal.indexOf("+") !== -1) {
                                filterVal = filterVal.split("+");
                            } else filterVal = [filterVal];
                        }
                        if (filterVal.length === 0) {
                            return true;
                        }

                        return filterVal.some((v) => entity[entityField.key] === v);
                    });
                } else if (fieldType === "multiselectlist") {
                    predicate = combinePredicate(predicate, (entity: T) => {
                        let filterVal = filter[filtername];
                        if (!filterVal) {
                            filterVal = [];
                        }

                        if (!entity[entityField.key]) return false;
                        if (typeof filterVal === "string") {
                            if (filterVal.indexOf("+") !== -1) {
                                filterVal = filterVal.split("+");
                            } else filterVal = [filterVal];
                        }
                        if (filterVal.length === 0) {
                            return true;
                        }
                        return filterVal.some((v) => entity[entityField.key].some((e) => e === v));
                    });
                } else if (fieldType === "bool") {
                    predicate = combinePredicate(predicate, (entity: T) => {
                        if (!entity) return false;
                        const filterVal = filter[filtername];
                        const hasField = Object.prototype.hasOwnProperty.call(entity, entityField.key);
                        if (!hasField && filterVal === "empty") return true;
                        if (filterVal == null || !hasField) return false;
                        if (filterVal === entity[entityField.key]) return true;
                        return false;
                    });
                }
            }
        });
    }

    return predicate;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function getFiltersAsUrlParams(filter: any, fieldtemplate: Entities.IFieldTemplate,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    searchable: Entities.IFilterTemplate): any {
    const res: any = {};
    if (filter) {
        if (/* searchable && searchable.textsearch && */ filter.searchtext && filter.searchtext.length > 2) {
            // res.push("searchtext=" + encodeURIComponent(filter.searchtext));
            res.searchtext = encodeURIComponent(filter.searchtext);
        } else {
            res.searchtext = "";
        }

        const filterKeys = Object.keys(filter).filter((n) => n !== "searchtext");
        const timeslottimes = ['timeslottime', 'timeslottimefrom', 'timeslottimeto'];
        filterKeys.forEach((n) => {
            let val = filter[n];
            if (typeof val === "string") {
                if (val) {
                    // res.push(n + "=" + encodeURIComponent(val));
                    res[n] = encodeURIComponent(val);
                }
            } else if (Array.isArray(val)) {
                if (val.length) {
                    val = val.map((v) => {
                        if (n === "timeslotday" && typeof v !== "string") {
                            return v.format("YYYY-MM-DD");
                        }

                        return v;
                    });
                    // res.push(n + "=" + encodeURIComponent(val.join("+")));
                    res[n] = encodeURIComponent(val.join("+"));
                }
            } else if (val && timeslottimes.indexOf(n) !== -1) {
                // res.push(n + "=" + val.hours + "%3A" + val.minutes);
                res[n] = encodeURIComponent(val.hours + ":" + val.minutes);
            } else if (val != null) {
                // res.push(n + "=" + encodeURIComponent(val + ""));
                res[n] = encodeURIComponent(val + "");
            }
        });
    }
    // return res.join("&");
    return res;
}

export function getFiltersFromUrlParams(qargs: any, fieldtemplate: Entities.IFieldTemplate,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    searchable: Entities.IFilterTemplate): Record<string, any> {
    const filters = {} as any;
    if (!qargs) return filters;

    const queryargs = qargs;
    Object.keys(queryargs).map((qa) => {
        try {
            const arg = decodeURIComponent(queryargs[qa]);
            queryargs[qa] = arg;
        // eslint-disable-next-line no-empty
        } catch (ex) {
        }
        return null;
    });

    if (queryargs && queryargs.searchtext) {
        filters.searchtext = queryargs.searchtext;
    }

    if (queryargs && queryargs.my_recommendations) {
        filters.my_recommendations = queryargs.my_recommendations === "true";
    }

    if (queryargs && queryargs.bookmarked) {
        filters.bookmarked = queryargs.bookmarked === "true";
    }
    
    if (fieldtemplate) {
        fieldtemplate.template.fields.forEach((entityfield) => {
            if (queryargs[entityfield.key]) {
                if (entityfield.type.toLowerCase() === "multiselectlist") {
                    filters[entityfield.key] = queryargs[entityfield.key].split("+");
                } else if (entityfield.type.toLowerCase() === "bool") {
                    if (queryargs[entityfield.key] === "empty") {
                        filters[entityfield.key] = "empty";
                    } else {
                        const bvalue = !!(queryargs[entityfield.key].toLowerCase() === "true"
                            || queryargs[entityfield.key] === "1");
                        filters[entityfield.key] = bvalue;
                    }
                } else {
                    filters[entityfield.key] = queryargs[entityfield.key];
                }
            }
        });
    }

    if (queryargs.timeslotday && queryargs.timeslotday !== "all") {
        try {
            const days = queryargs.timeslotday.split("+").map((d) => moment(d, "YYYY-MM-DD", true)).filter((d) => d.isValid());
            // TODO : TRADS
            filters.timeslotday = days.map((d) => d.format("YYYY-MM-DD"));
        } catch (exception) {
            console.error("error getting param from url", exception);
        }
    }

    const timeArg = (name) => {
        if (queryargs[name]) {
            const timeparts = queryargs[name].split(":");
            filters[name] = {
                hours: timeparts[0],
                minutes: timeparts[1]
            };
        }
    };
    timeArg("timeslottime");
    timeArg("timeslottimefrom");
    timeArg("timeslottimeto");

    return filters;
}

function isNullOrUndefined(item) {
    if (item === null || item === undefined) return true;
    return false;
}

export function sortEntities(entities: any, sort?: Entities.ISortTemplate) {
    if (sort && sort.propName) {
        const descSort = sort.order === 'desc';
        return entities.sort((a, b) => {
            const aProp = getPropertyValue(a, sort.propName);
            const bProp = getPropertyValue(b, sort.propName);

            if (!isNullOrUndefined(aProp) && !isNullOrUndefined(bProp)) {
                if (!descSort) {
                    if (aProp < bProp) {
                        return -1;
                    }

                    if (aProp > bProp) {
                        return 1;
                    }
                } else {
                    if (aProp < bProp) {
                        return 1;
                    }

                    if (aProp > bProp) {
                        return -1;
                    }
                }
            }

            return 0;
        });
    }

    return entities;
}
